<template>
  <page-container title="违约情况" :breadcrumb="breadcrumb">
    <!--    查询栏-->
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">

            <a-form-model-item label="字典类型名称" prop="name">
              <a-input v-model="queryParams.name" placeholder="请输入字典类型名称"></a-input>
            </a-form-model-item>
            <a-form-model-item label="字典类型编码" prop="code">
              <a-input v-model.trim="queryParams.code" placeholder="请输入字典类型编码"></a-input>
            </a-form-model-item>
            <div class="query-btns">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
              <a-button type="link" @click="showAdvanced=!showAdvanced">展开
                <a-icon :type="showAdvanced?'up':'down'"></a-icon>
              </a-button>
            </div>
          </a-form-model>
        </div>
        <div v-show="showAdvanced" class="advanced-query">
          <a-form-model ref="advancedForm" :model="queryParams" layout="inline" class="query-form">

            <a-form-model-item label="字典类型备注" prop="remark">
              <a-input v-model.trim="queryParams.remark" placeholder="请输入字典类型备注"></a-input>
            </a-form-model-item>
          </a-form-model>
        </div>
      </div>
    </div>
    <!--    展示列表栏-->
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">字典类型列表</h3>
          <div class="table-btns">
            <a-button type="primary" @click="showModal('add')">
              <a-icon type="plus"></a-icon>
              新建
            </a-button>
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData(true)"></a-icon>
          </div>
        </div>
        <a-table :columns="tableColumns" :data-source="tableData" row-key="id" :loading="tableLoading"
                 :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')"
                 style="margin-top: 20px;" :pagination="false">
          <span slot="operation" slot-scope="value, record">
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                <a-icon type="menu"/>
              </a>
              <a-menu slot="overlay" @click="operationClick">
                <a-menu-item :key="'edit-'+record.id">修改</a-menu-item>
                <a-menu-item :key="'delete-'+record.id">删除</a-menu-item>
              </a-menu>
            </a-dropdown>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer
                      @change="pageChange" @showSizeChange="onShowSizeChange"
                      :show-total="(total, range) => `共${total}条`"
                      style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <!--    新建、修改违约页面-->
    <a-modal v-model="modalVisible" :title="modalTitle" :width="900" :dialog-style="{top: '25px'}"
             :body-style="modalBodyStyle">
      <template slot="footer">
        <a-button v-if="modalType==='add'||modalType==='edit'" @click="modalCancel">取消</a-button>
        <a-button type="primary" @click="modalConfirm">提交</a-button>
      </template>
      <a-form-model ref="modalForm" :model="modalForm" :rules="modalRules" layout="inline" class="form-in-twocolumns"
                    :label-col="{span:9}" :wrapper-col="{span:14}">

        <a-form-model-item label="字典类型名称" prop="name">
          <a-input v-model.trim="modalForm.name" placeholder="请输入字典类型名称"/>
        </a-form-model-item>
        <a-form-model-item label="字典类型编码" prop="code">
          <a-input v-model="modalForm.code" placeholder="请输入字典类型编码"></a-input>
        </a-form-model-item>
        <a-form-model-item label="字典类型备注" prop="remark">
          <a-input v-model="modalForm.remark" placeholder="请输入字典类型备注"></a-input>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </page-container>
</template>

<script>
import {
  addDictionaryType,
  getDictionaryTypeListByCondition,
  deleteOneDictionaryType,
  editDictionaryType
} from 'A/xtpz'
import {
  getItemFromArrayByKey,
} from 'U'
import moment from 'moment'
import deptselect from '@/mixins/deptselect'

export default {
  name: 'dictionaryTypeManagement',
  mixins: [deptselect],
  data() {
    return {
      showAdvanced: false,
      moment,
      modalBodyStyle: {
        maxHeight: '560px',
        overflowY: 'auto',
      },
      breadcrumb: [
        {
          name: '系统配置',
          path: ''
        },
        {
          name: '字典管理',
          path: ''
        },
       {
         name: '字典类型管理',
             path: ''
       }
      ],
      queryParams: {
        name: '',
        code: '',
        remark: '',
      },
      tableColumns: [
        {
          title: '字典类型名称',
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: '字典类型编码',
          dataIndex: 'code',
          key: 'code'
        },
        {
          title: '字典类型备注',
          dataIndex: 'remark',
          key: 'remark'
        },
        {
          title: '操作',
          key: 'operation',
          scopedSlots: {customRender: 'operation'}
        },
      ],
      tableData: [],
      tableLoading: false,
      currentPage: 1,
      pagination: {
        total: 0,
        pageSize: 10,
      },
      modalVisible: false,
      modalType: '',
      modalForm: {
        id: 0,
        name: '',
        code: '',
        remark: '',
      },
      modalRules: {
        name: [{required: true, message: '请选择字典类型名称'}],
        code: [{required: true, message: '请输入字典类型编码'}],
      },
      selectedDictionaryType: null
    }
  },
  computed: {
    modalTitle() {
      let title = '';
      if (this.modalType == 'add') {
        title = '新增';
      } else if (this.modalType == 'edit') {
        title = '修改';
      } else {
        title = '';
      }
      return title;
    },
  },
  watch: {},

  created() {
    this.init();
  },
  methods: {
    init() {
      this.getTableData();
    },
    resetQueryParams() {
      this.$refs.queryForm.resetFields();
      this.$refs.advancedForm.resetFields();
    },
    getTableData(firstPage) {
      if (firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        ...this.queryParams,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize,
      };
      console.log(params)
      getDictionaryTypeListByCondition(params).then(res => {
        this.tableLoading = false;
        console.log(res.item)
        if (res && res.returncode === '0') {
          this.tableData = res.item ? res.item : []
          this.pagination.total = res.count;
        }
      }).catch(err => {
        console.log(err)
        this.tableLoading = false;
      })
    },
    pageChange(page, size) {
      this.getTableData();
    },
    onShowSizeChange(page, size) {
      this.pagination.pageSize = size;
      this.getTableData(true);
    },
    showModal(type, value, record) {
      this.modalType = type;
      if (type == 'add') {
        this.modalVisible = true;
        this.modalForm.id = 0
        this.modalForm.name = ''
        this.modalForm.code = ''
        this.modalForm.remark = ''
      } else {
        this.modalVisible = true;
        this.modalForm.id = record.id
        this.modalForm.name = record.name
        this.modalForm.code = record.code
        this.modalForm.remark = record.remark
      }
    },
    deleteConfirm(value, record) {
      this.$confirm('确定要删除该数据？',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.delete(record.id);
      }).catch(()=>{
      });
    },
    delete(id) {
      if (id) {
        let params = {
          id
        };
        deleteOneDictionaryType(params).then(res => {
          if (res && res.returncode == '0') {
            this.$message.success('操作成功');
            this.getTableData();
          } else {
            this.$message.error(res.errormsg || '操作失败');
          }
        })
      }
    },
    modalConfirm() {
      if (this.modalType == 'add' || this.modalType == 'edit') {
        this.$refs.modalForm.validate(valid => {
          if (valid) {
            this.addOrEdit();
          } else {
            return false;
          }
        })
      } else {
        this.modalVisible = false;
      }
    },
    //添加或者编辑违约单
    addOrEdit() {
      this.showLoading();
      console.log(this.modalForm)
      let params = {
        name: this.modalForm.name,
        code: this.modalForm.code,
        remark: this.modalForm.remark,
      };
      if (this.modalType == 'add') {
        console.log(params)
        addDictionaryType(params).then(
            this.addOrEditResult);
      } else if (this.modalType == 'edit') {
        params.id = this.modalForm.id;
        console.log(params)
        editDictionaryType(params).then(this.addOrEditResult)
      }
    },
    addOrEditResult(res) {
      this.hideLoading();
      if (res && res.returncode == '0') {
        this.$message.success('操作成功');
        this.modalVisible = false;
        this.getTableData();
      } else {
        this.$message.error(res.errormsg || '操作失败');
      }
    },
    modalCancel() {
      this.modalVisible = false;
    },
    resetModal() {
      this.$refs.modalForm.resetFields();
    },
    operationClick({key}) {
      console.log(key)
      let arr = key.split('-');
      let type = arr[0];
      let id = arr[1];
      console.log(arr)
      this.selectedDictionaryType = getItemFromArrayByKey(this.tableData, 'id', id);
      console.log(this.selectedDictionaryType)
      if ('edit' == type) {
        this.showModal('edit', id, this.selectedDictionaryType)
      } else if ('delete' == type) {
        this.deleteConfirm(id, this.selectedDictionaryType)
      }
    },
  }
}
</script>

<style scoped>

</style>